import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  clearLocalStorage(): void {
    const languageId = this.getLanguageID();
    localStorage.clear();
    this.addLanguageID(languageId || environment.languageId);
  }

  private _getItem(key: string): string | null {
    return localStorage.getItem(`fastfisio_${key}`);
  }

  private _addItem(key: string, value: string): void {
    localStorage.setItem(`fastfisio_${key}`, value);
  }

  private _removeItem(key: string): void {
    localStorage.removeItem(`fastfisio_${key}`);
  }

  addLanguageID(lang: string): void {
    this._addItem('language_id', lang);
  }

  getLanguageID(): string | null {
    return this._getItem('language_id');
  }

  addAuthToken(token: string): void {
    this._addItem('authToken', token);
  }

  removeAuthToken(): void {
    this._removeItem('authToken');
  }

  getAuthToken(): string | null {
    return this._getItem('authToken');
  }
}
