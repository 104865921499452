import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdminFullDataPayload } from '../../core/model/admin.model';
import { ProfessionalFullDataPayload } from '../../core/model/professional.model';
import { Role } from '../enums/role';
import { LoginResponse, LoginUser } from '../interfaces/login.interfaces';
import { UserJwtPayload } from '../interfaces/user-jwt';
import { LocalStorageService } from './local-storage.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn$ = new BehaviorSubject<boolean>(false);
  private userSubject: BehaviorSubject<UserJwtPayload | null> =
    new BehaviorSubject<UserJwtPayload | null>(null);

  #router = inject(Router);
  #http = inject(HttpClient);
  #localStorage = inject(LocalStorageService);
  #jwtHelper = new JwtHelperService();
  #logger = inject(LoggerService);

  get user(): Observable<UserJwtPayload | null> {
    return this.userSubject.asObservable();
  }

  get userSnapshot(): UserJwtPayload | null {
    return this.userSubject.getValue();
  }

  getUserFullData(): Observable<
    ProfessionalFullDataPayload | AdminFullDataPayload | null
  > {
    if (this.userSnapshot?.role === Role.PROFESSIONAL) {
      const params = new HttpParams().set('email', this.userSnapshot!.email);
      return this.#http.get<ProfessionalFullDataPayload>(
        `${environment.organizationgooUrl}/staff/email`,
        { params }
      );
    } else if (this.userSnapshot?.role === Role.ADMIN) {
      const params = new HttpParams().set('email', this.userSnapshot!.email);
      return this.#http.get<any>(`${environment.globalgooUrl}/user/email`, {
        params,
      });
    }
    return of(null);
  }

  get isLoggedIn() {
    return this.loggedIn$.asObservable();
  }

  login(user: LoginUser): Observable<any> {
    return this.#http.post<LoginResponse>(
      `${environment.shoppinggooUrl}/auth/authenticate`,
      user
    );
  }

  loadUser(jwtToken: string): void {
    if (!this.#jwtHelper.isTokenExpired(jwtToken)) {
      const decodedToken: UserJwtPayload = this.#jwtHelper.decodeToken(jwtToken)!;
      if (jwtToken) {
        this.userSubject.next(decodedToken);
        this.loggedIn$.next(true);
      } else {
        this.#logger.error('AuthService: ', 'Token not found\n');
        this.logout();
      }
    } else {
      this.#logger.error('AuthService: ', 'Token expired\n');
      this.logout();
    }
  }

  loadUserJwt(jwtToken: string): boolean {
    this.#localStorage.addAuthToken(jwtToken);
    if (jwtToken) {
      const decodedToken: UserJwtPayload =
        this.#jwtHelper.decodeToken(jwtToken)!;
      if (decodedToken.role != Role.ADMIN) {
        this.userSubject.next(decodedToken);
        this.loggedIn$.next(true);
        return true;
      } else {
        this.loggedIn$.next(false);
        this.userSubject.next(null);
        this.#localStorage.clearLocalStorage();
        return false;
      }
    } else {
      this.loggedIn$.next(false);
      this.userSubject.next(null);
      this.#localStorage.clearLocalStorage();
      return false;
    }
  }

  loadUserJwtAdmin(jwtToken: string): boolean {
    this.#localStorage.addAuthToken(jwtToken);
    if (jwtToken) {
      const decodedToken: UserJwtPayload =
        this.#jwtHelper.decodeToken(jwtToken)!;
      if (decodedToken.role === Role.ADMIN) {
        this.userSubject.next(decodedToken);
        this.loggedIn$.next(true);
        return true;
      } else {
        this.loggedIn$.next(false);
        this.userSubject.next(null);
        this.#localStorage.clearLocalStorage();
        return false;
      }
    } else {
      this.loggedIn$.next(false);
      this.userSubject.next(null);
      this.#localStorage.clearLocalStorage();
      return false;
    }
  }

  logout() {
    this.loggedIn$.next(false);
    this.userSubject.next(null);
    this.#localStorage.clearLocalStorage();
    this.#router.navigate(['/login']);
  }

  refreshToken() {
    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) {
      return of();
    }
    return this.#http.get<LoginResponse>(
      `${environment.shoppinggooUrl}/auth/refresh`
    );
  }

  hasAnyRole(allowedRoles: Array<Role>): boolean {
    const user = this.userSubject.getValue();
    if (!user || !user.role) {
      return false;
    }

    return allowedRoles.includes(user.role as Role);
  }
}
